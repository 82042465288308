export default [
    {
      title: 'Management',
      icon: 'FolderIcon',
      tag: '2',
      tagVariant: 'light-warning',
      children: [
        {
          title: 'Payment Lists',
          route: 'payment-lists',
        },
        {
          title: 'Withdraw Lists',
          route: 'withdraw-lists',
        },
      ],
    },
  ]
  